.MainWrapper{
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
min-height: 100vh;
padding: 25px 0px;

text-align: justify;
}
.wrapper{
    min-height: 75vh;
    width: 50vw;
    border-radius: 5px;
    padding: 12px;
    color: #6996DBDE;
}
.wrapper h1{
    color: #6996DBDE;
    font-size: 1.5rem;
}
.MainWrapper img{
  width: 150px;

}
footer a {
  color: #597CB1;
  text-decoration: underline;
}
@media screen and (max-width: 972px) {
  .wrapper{
    width: 85vw;
  }
}