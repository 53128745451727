html {
  scroll-behavior: smooth;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media screen and (max-width: 600px) {
  .Bottom {
    flex-direction:column!important;
    padding: 20px!important;

  }
  .bottom-right {
    text-align:center!important;
    margin-top:20px;
  }
  .App-header h1{
    font-size: calc(30px + 8vmin)!important;
    font-weight: 900;
    margin:0;
  }
  .App-header h3{
    display:block!important;
  }
  .hero-img {
    margin-right: unset!important;
    width: 100%;
  }
  .text-img {
display:none;
  }
}
.header{
  display: flex;
  justify-content: center;
}
.App-header {
  background-color: #FB9F00;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color:white;
  /*color: white;*/
}
.App-header h1{
  font-size: calc(100px + 8vmin);
  font-weight: 900;
  margin:0;
}
.App-header h3{
  display:none;
}

.Content {
  position:relative;
}
.container {
  position: relative;
  width: fit-content;
  margin: 0 auto;
}
.text-img {
  position: absolute;
  top: -40px;
  left: -70px;
  max-width: max-content;
  width: 100%;
}

.hero-img {
  max-width: max-content;
  margin-top: -170px;
  margin-right: -110px;
}

.Bottom {
  border-top: 2px solid #031D44;
  padding: 50px;
  font-size: 16px;
  display:flex;
  flex-direction:row;
  justify-content: space-between;
}
.bottom-left img{
  max-width: max-content;
  width: 100%;
}
.bottom-right {
  text-align:right;
}
.bottom-right span{
  font-size: 20px;
}
.bottom-right a{
  color: #597CB1
}
footer {
  background: #031D44;
  padding: 12px;
}
footer span{
  padding: 0px 5px;
}
.gear-spin {
  --fa-animation-duration: 15s;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a{
  text-decoration: none;
}
a:visited{
  color: #597CB1;
}
